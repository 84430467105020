/**
 * Direttiva che crea md-chips per la ricerca di tags
 */

import { IApplicationScope } from "../../shared/global/applicationScope";
import { SenecaResponse } from "atfcore-commonclasses";
import { Tag } from "atfcore-commonclasses";

export interface IfilterChipsDirectiveScope extends IApplicationScope {
    type: string;
    filterPlaceholder: string;
    filteredTags: Array<any>;
    searchTag: Function;
    transformChip: Function;
    addTagToProject: Function;
    addTag: Function;
    removeTagToProject: Function;
    removeTag: Function;
    searchRemoteTagsPromise: any;
    automaticallyOpenOptions: Function;
    selectedFilters: any;
    getFilterPlaceholder: Function;
    competenceBehaviours: any[];
    searchRemoteFlatTagsPromise: any;
    getChipName: Function;
    getFlatTagsPromise: any;
    getTagsFlatPromise: any;
}

angular.module('app').directive('filterChips', ($window, $timeout, $translate, SearchTagsService, toaster, $rootScope, LibraryApplicationData, $q) => {
    return {
        restrict: 'AEC',
        scope: {
            type: "@",
            selectedFilters: "=",
            addTagToProject: "&",
            removeTagToProject: "&",
            isScormDisabled: '&'
        },
        templateUrl: 'app/shared/filterChips/filterChips.html',
        link: ($scope: IfilterChipsDirectiveScope, element: JQuery, attr: ng.IAttributes) => {
            // Unwrappo le funzioni che aggiungono e rimuovono il tag al progetto, altrimenti non riesco a passare un parametro al controller che chiama la direttiva
            $scope.addTag = $scope.addTagToProject();
            $scope.removeTag = $scope.removeTagToProject();

            angular.element(document).ready(() => {
                let x = element[0].querySelector('input')
                let y = angular.element(x)
            });

            // Lista contenente i tag recuperati con la ricerca
            $scope.filteredTags = [];

            // Imposto i placeholder in base alla tipologia di chip
            $scope.getFilterPlaceholder = () => {
                if ($scope.type == 'langs') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_LANG");
                } else if ($scope.type == 'softSkill') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_SOFT_SKILL");
                } else if ($scope.type == 'techSkill') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_TECH_SKILL");
                } else if ($scope.type == 'topic' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_TOPIC");
                } else if ($scope.type == 'behaviour') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_COMPETENCE_BEHAVIOUR");
                } else if ($scope.type == 'type') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_TYPE");
                } else if ($scope.type == 'subtype' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_SUBTYPE");
                } else if ($scope.type == 'cluster' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_CLUSTER");
                } else if ($scope.type == 'adminTag') {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_ADMIN_TAGS");
                } else if ($scope.type == 'functionalArea' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_FUNCTIONAL_AREA");
                } else if ($scope.type == 'level' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_LEVEL");
                } else if ($scope.type == 'streams' && (!$scope.selectedFilters || !$scope.selectedFilters.length)) {
                    return $translate.instant("home.tabs.filters.inputPlaceholder.SEARCH_STREAM");
                }

                return null;
            }

            $scope.automaticallyOpenOptions = (): any => {
                // Si possono avere al massimo 1 sottotipo, 1 tag cluster, admin tag, functionArea, stream e level. Pertanto non avvio la ricerca se ne ho già selezionato uno
                if (($scope.type == 'cluster' || $scope.type == 'topic' || $scope.type == 'subtype' || $scope.type == 'functionalArea' || $scope.type == 'streams' || $scope.type == 'level') && $scope.selectedFilters && $scope.selectedFilters.length) {
                    return null;
                }

                return 0;
            }

            // Costruisce la chip del comportamento/competenze
            let getCompetenceBehaviourName = (behaviourName: string, parentTagId: string) => {
                let currentCompetenceName = '';
                if ($scope.competenceBehaviours && $scope.competenceBehaviours.length) {
                    for (let i = 0, competenceBehavioursLength = $scope.competenceBehaviours.length; i < competenceBehavioursLength; i++) {
                        let currentCompetencBehaviour = $scope.competenceBehaviours[i];
                        if (currentCompetencBehaviour.tagId === parentTagId) {
                            currentCompetenceName = currentCompetencBehaviour.title;
                            break;
                        }
                    }
                }
                return currentCompetenceName + ' - ' + behaviourName;
            }

            // Ritorna il nome da visualizzare come chip
            $scope.getChipName = (chip: any): string => {
                if (chip) {
                    // Se si tratta del comportamento di un comportamento devo mostrare anche la competenza di cui fa parte (il valore del tag padre, ossia della competenza, è salvata all'interno dell'attributeValue, qualora non sia presa dall'array passato dal controller item; se invece è stata selezionata una nuova chip, significa che non ho ancora salvato l'item, non ho l'attributo salvato dentro l'item, quindi ho già tutti i dati necessari per costruire il titolo)
                    if (chip.isJumpinBehaviour) {
                        let customName = getCompetenceBehaviourName(chip.title, chip.parentTagId);
                        return customName;
                    } else if (chip.attributeType && chip.attributeType === LibraryApplicationData.constants.JUMPIN_BEHAVIOUR) {
                        let customName = chip.title + ' - ' + (chip.crossReferenceObject && chip.crossReferenceObject.title);
                        return customName;
                    } else {
                        return chip.title || chip.desc || (chip.crossReferenceObject && chip.crossReferenceObject.title) || '';
                    }
                }
            }

            // Effettua la ricerca dei tag in base alla descrizione
            let searchRemoteTags = (tagTitle: string) => {
                return $q((resolve: Function, reject: Function) => {
                    // Preparo i dati calcolati da inviare al server
                    let fromRecord: number = 0, // numero di record da
                        numRecords: number = 50, // a
                        type: string = null // tipo di tag che sto cercando

                    // Verifico che tipo di tag sto cercando
                    if ($scope.type == 'softSkill') {
                        type = LibraryApplicationData.constants.SOFT_COMPETENCES;
                    } else if ($scope.type == 'techSkill') {
                        type = LibraryApplicationData.constants.TECH_COMPETENCES;
                    } else if ($scope.type == 'topic') {
                        type = LibraryApplicationData.constants.ARGUMENTS;
                    } else if ($scope.type == 'cluster') {
                        type = LibraryApplicationData.constants.CLUSTERS;
                    } else if ($scope.type == 'functionalArea') {
                        type = LibraryApplicationData.constants.FUNCTIONAL_AREAS;
                    } else if ($scope.type == 'adminTag') {
                        type = LibraryApplicationData.constants.ADMIN_TAGS;
                    } else if ($scope.type == 'level') {
                        type = LibraryApplicationData.constants.LEVELS;
                    } else if ($scope.type == 'streams') {
                        type = LibraryApplicationData.constants.STREAMS;
                    } else if ($scope.type == 'langs') {
                        type = LibraryApplicationData.constants.LANG;
                    }

                    // Eseguo la ricerca, eliminando le eventuali altre pendenti
                    if ($scope.searchRemoteTagsPromise) {
                        $scope.searchRemoteTagsPromise.$cancelRequest();
                    }
                    $scope.searchRemoteTagsPromise =
                        SearchTagsService.searchTags.query({
                            fromRecord: fromRecord,
                            numRecords: numRecords,
                            title: tagTitle,
                            type: type
                        });
                    $scope.searchRemoteTagsPromise.$promise
                        .then((data: SenecaResponse<Array<any>>) => {
                            if (data.error) {
                                // Dati non validi, quindi alzo l'errore
                                toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
                                reject();
                            } else {
                                // Se è presente un ordinamento, li ordino
                                if (data.response && data.response.length) {
                                    for (let i = 0; i < data.response.length; i++) {
                                        let currentResult = data.response[i];
                                        if (currentResult && currentResult.tagAttributes && currentResult.tagAttributes.length) {
                                            for (let k = 0; k < currentResult.tagAttributes.length; k++) {
                                                let currentAttribute = currentResult.tagAttributes[k];
                                                if (currentAttribute.attributeType == LibraryApplicationData.constants.ORDER) {
                                                    currentResult.order = currentAttribute.attributeValue;
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                    data.response.sort((a: any, b: any) => {
                                        return parseFloat(a.order) - parseFloat(b.order);
                                    });
                                }

                                // Torno i risultati della ricerca
                                resolve(data.response);
                            }
                            // Annullo la promessa
                            $scope.searchRemoteTagsPromise = null;
                        })
                        .catch((error: any) => {
                            // Annullo la promessa
                            $scope.searchRemoteTagsPromise = null;
                            // Non mostro la modale di errore se ho cancellato volutamente la richiesta
                            if (!error || error.config.timeout.$$state.status !== 1) {
                                // Verifico se è un problema di connettività
                                let errorMessage: string = null;

                                // Nuovo oggetto d'errore
                                let newError: any = {
                                    severity: "danger"
                                }

                                if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
                                    // Problema di connettività
                                    errorMessage = $translate.instant("error.generic.NO_SERVER_TITLE");
                                    newError.hideUnknown = true;
                                } else {
                                    // Messaggio di errore generico
                                    errorMessage = $translate.instant("error.generic.UNKNOWN_ERROR");
                                }

                                // Imposto il messaggio
                                newError.message = errorMessage;

                                // Lo aggiungo alla lista
                                let errors: Array<any> = [];
                                errors.push(newError);

                                // E apro la modale
                                $rootScope.$emit("showApplicationModalErrors", errors);
                            }
                            reject();
                        });
                });
            }

            let searchLocaleTags = (searchedText: string) => {
                let localTypeTags = LibraryApplicationData.getItemTypesList();

                // Conenitore degli array filtrati
                let results: Array<any> = [];
                if ((/^\d+$/.test(searchedText))) {
                    results = searchedText ? localTypeTags.filter(
                        (desc: any) => {
                            let regex = new RegExp(searchedText, 'gi');
                            return desc.code.match(regex);
                        }
                    ) : localTypeTags;
                } else {
                    results = searchedText ? localTypeTags.filter(
                        (desc: any) => {
                            let lowercaseQuery = angular.lowercase(searchedText);
                            let regex = new RegExp(lowercaseQuery, 'gi');
                            return desc.desc.match(regex);
                        }
                    ) : localTypeTags;
                }
                return results;
            };

            // Cerca, in locale, la lista dei sottotipi
            let searchLocaleSubtypes = (searchedText: string) => {
                // Contenitore di tutti i tag (della topologia) locali
                let localSubtypeTags = LibraryApplicationData.getItemSubtypesList();

                // Conenitore degli array filtrati
                let results: Array<any> = [];
                if ((/^\d+$/.test(searchedText))) {
                    results = searchedText ? localSubtypeTags.filter(
                        (desc: any) => {
                            let regex = new RegExp(searchedText, 'gi');
                            return desc.code.match(regex);
                        }
                    ) : localSubtypeTags;
                } else {
                    results = searchedText ? localSubtypeTags.filter(
                        (desc: any) => {
                            let lowercaseQuery = angular.lowercase(searchedText);
                            let regex = new RegExp(lowercaseQuery, 'gi');
                            return desc.desc.match(regex);
                        }
                    ) : localSubtypeTags;
                }
                return results;
            };

            // Cerca la lista di comportamenti/competenze da remoto
            let getFlatTagsByParentIds = () => {
                return $q((resolve: Function, reject: Function) => {
                    // Eseguo la ricerca, eliminando le eventuali altre pendenti
                    if ($scope.searchRemoteFlatTagsPromise) {
                        $scope.searchRemoteFlatTagsPromise.$cancelRequest();
                    }
                    $scope.searchRemoteFlatTagsPromise =
                        SearchTagsService.getFlatTagsByParentIds.query({
                            tagIds: ["ad1478ee-99b8-42f6-9199-7d66b48e45fe",
                                "3740301b-3503-4b41-9d43-314a83c1640f",
                                "d53784b7-203c-46a6-a91b-8e04a262b4e4",
                                "7b71a015-99ae-4c81-904f-aac010a8d20c",
                                "9e792d63-733d-45aa-a934-34dbd94207b8",
                                "0c5114dd-ad16-4c57-8448-9356c2a6a6ba"]
                        });
                    $scope.searchRemoteFlatTagsPromise.$promise
                        .then((data: SenecaResponse<Array<string>>) => {
                            if (data.error) {
                                // Dati non validi, quindi alzo l'errore
                                toaster.pop("error", $translate.instant('error.generic.TITLE'), $translate.instant('error.generic.MESSAGE'));
                                reject();
                            } else {
                                // Torno i risultati della ricerca
                                resolve(data.response);
                            }
                            // Annullo la promessa
                            $scope.searchRemoteFlatTagsPromise = null;
                        })
                        .catch((error: any) => {
                            // Annullo la promessa
                            $scope.searchRemoteFlatTagsPromise = null;
                            // Non mostro la modale di errore se ho cancellato volutamente la richiesta
                            if (!error || error.config.timeout.$$state.status !== 1) {
                                // Verifico se è un problema di connettività
                                let errorMessage: string = null;

                                // Nuovo oggetto d'errore
                                let newError: any = {
                                    severity: "danger"
                                }

                                if (!error.data && error.status == -1 && (!error.statusText || !error.statusText.length) || error.status == 504 || error.status == 502) {
                                    // Problema di connettività
                                    errorMessage = $translate.instant("error.generic.NO_SERVER_TITLE");
                                    newError.hideUnknown = true;
                                } else {
                                    // Messaggio di errore generico
                                    errorMessage = $translate.instant("error.generic.UNKNOWN_ERROR");
                                }

                                // Imposto il messaggio
                                newError.message = errorMessage;

                                // Lo aggiungo alla lista
                                let errors: Array<any> = [];
                                errors.push(newError);

                                // E apro la modale
                                $rootScope.$emit("showApplicationModalErrors", errors);
                            }
                            reject();
                        });
                });
            }

            let filterFlatTags = (searchedText: string) => {
                // Conenitore degli array filtrati
                let results: Array<any> = [];
                if ((/^\d+$/.test(searchedText))) {
                    results = searchedText ? $scope.competenceBehaviours.filter(
                        (title: any) => {
                            let regex = new RegExp(searchedText, 'gi');
                            return title.code.match(regex);
                        }
                    ) : $scope.competenceBehaviours;
                } else {
                    results = searchedText ? $scope.competenceBehaviours.filter(
                        (title: any) => {
                            let lowercaseQuery = angular.lowercase(searchedText);
                            let regex = new RegExp(lowercaseQuery, 'gi');
                            return title.title.match(regex);
                        }
                    ) : $scope.competenceBehaviours;
                };

                return results;
            }

            // Ordina i flat tag in base al loro parentId
            let sortFlatTagsByParentIds = (a: any, b: any) => {
                if (a.parentTagId < b.parentTagId) {
                    return -1;
                }
                if (a.parentTagId > b.parentTagId) {
                    return 1;
                }
                return 0;
            }

            // Cerca la lista di comportamenti/competenze
            let searchFlatTagsByParentIds = (searchedText: string) => {
                return $q((resolve: Function, reject: Function) => {
                    $scope.getFlatTagsPromise = getFlatTagsByParentIds();
                    $scope.getFlatTagsPromise.then((data: any) => {
                        $scope.competenceBehaviours = data;
                        let results = filterFlatTags(searchedText);
                        // Costruisco un nuovo array, in cui creo un nuovo oggetto per ogni gruppo; il nuovo oggetto conterrà le informazioni del padre
                        // Pertanto sarà: [ { OGGETTO PADRE (COMPETENZA) }, { oggetto figlio (comportamento)}, { oggetto figlio (comportamento) },
                        // { OGGETTO PADRE (COMPETENZA) }, { oggetto figlio (comportamento)}, { oggetto figlio (comportamento)}]
                        let newResultContainerTmp: any[] = [];
                        if (results && results.length) {
                            // Ordino i risultato, raggruppandoli per tagId dei loro parent
                            results.sort(sortFlatTagsByParentIds);
                            for (let i = 0, resultsLength = results.length; i < resultsLength; i++) {
                                let currentResult = results[i];
                                // Dato che sono raggruppati, basta valuto il tagParentId dell'ultimo elemento aggiunto
                                let parentAlreadyAdded = false;

                                let previousResult = newResultContainerTmp.length && newResultContainerTmp[newResultContainerTmp.length - 1] && newResultContainerTmp[newResultContainerTmp.length - 1];
                                if (previousResult) {
                                    if (previousResult.parentTagId === currentResult.parentTagId) {
                                        parentAlreadyAdded = true;
                                    }
                                }

                                if (!parentAlreadyAdded) {
                                    // Aggiungo l'oggetto rappresentante il tag padre (competenza)
                                    let tagParenObj = {
                                        title: currentResult.parentTitle,
                                        parentTagId: currentResult.parentTagId,
                                        tagId: currentResult.parentTagId,
                                        isParentFlatTag: true
                                    };
                                    newResultContainerTmp.push(tagParenObj);
                                }
                                currentResult.isJumpinBehaviour = true;
                                newResultContainerTmp.push(currentResult);
                            }
                        }
                        $scope.competenceBehaviours = newResultContainerTmp;
                        $scope.getFlatTagsPromise = null;
                        resolve(newResultContainerTmp);
                    });
                });
            };

            $scope.searchTag = (searchedText: string) => {
                let results: any = [];
                if (searchedText && searchedText == '') {
                    // Torno un array vuoto
                    results = $scope.filteredTags;
                } else if ($scope.type == 'type') {
                    // Le chips della tipologia è un elenco predefinito quindi non serve nessuna chiamata al server
                    results = searchLocaleTags(searchedText);
                } else if ($scope.type == 'subtype') {
                    // Le chips dei sottotipi è un elenco predefinito quindi non serve nessuna chiamata al server
                    results = searchLocaleSubtypes(searchedText);
                } else if ($scope.type == 'behaviour') {
                    // Recupero le competenze/comportamenti
                    results = searchFlatTagsByParentIds(searchedText);
                } else {
                    // Chiamo il server per recuperare le informazioni
                    results = searchRemoteTags(searchedText);
                }
                return results;
            };

            $scope.transformChip = (chip: any) => {
                return chip;
            }
        }
    };
});